<template>
  <gw-dialog v-model="dialog" :loading="loading" @open:dialog="onOpen">
    <template #title>
      <v-card-title>
        <span class="text-h5">{{ $t("invoice.to.email") }}</span>
      </v-card-title>
    </template>

    <v-container>
      <v-row>
        <v-col cols="12">
          <form-field-email v-model="sEmail" multiple required />
        </v-col>
      </v-row>
    </v-container>

    <template #prepend-actions>
      <v-btn
        :loading="loading"
        :disabled="!canSend"
        color="primary"
        text
        @click="onSend"
        v-text="$t('send')"
      />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import type { FirmData } from "@planetadeleste/vue-mc-gw/src/types";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { isEmpty } from "lodash";

@Component({
  components: { GwDialog },
})
export default class InvoiceSendToEmail extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly invoice!: InvoiceData;

  loading = false;
  obInvoice: Invoice | null = null;
  sEmail: string | null = null;

  get validEmails() {
    const reg =
      /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,\s{0,1}){0,1})+$/;
    return reg.test(this.sEmail || "");
  }

  get canSend() {
    return !isEmpty(this.sEmail) && this.validEmails;
  }

  get emailRules() {
    return isEmpty(this.sEmail) || this.validEmails
      ? []
      : ["Formato de correo/s invalido/s. Recuerde separarlos por coma."];
  }

  async onOpen() {
    if (!this.invoice || !this.invoice.id) {
      return;
    }

    this.obInvoice = new Invoice({ id: this.invoice.id });
    await this.obInvoice.fetch();

    const obCustomer: FirmData = this.obInvoice.get("customer_firm");
    if (obCustomer && obCustomer.email) {
      this.sEmail = obCustomer.email;
    }
  }

  async onSend() {
    if (!this.sEmail || !this.obInvoice || !this.obInvoice.id) {
      return;
    }

    this.loading = true;
    const obData = { id: this.obInvoice.id, email: this.sEmail };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.send_email", obData),
      method: "POST",
      data: obData,
    };
    const obResponse = await this.obInvoice.createRequest(obConfig).send();
    this.loading = false;

    if (obResponse) {
      const obResponseData = obResponse.getData();
      const sMessage = obResponseData.message;

      if (sMessage) {
        if (!obResponseData.status) {
          this.$toast.error(sMessage);

          return;
        } else {
          this.$toast.success(sMessage);
        }
      } else if (obResponseData.status) {
        this.$toast.success(this.$t("invoice.email.sent"));
      }
    }

    this.sEmail = null;

    // Close dialog
    this.$emit("input", false);
  }
}
</script>
